.logo-container h1 a,
.navigation-panel__logo a,
.navigation-panel__logo a:any-link,
.navigation-panel__logo a:visited,
.navigation-panel__logo a:focus,
.navigation-panel__logo a:hover {
  background-image: url("https://s3-m.lonet.org/site_uploads/files/000/000/001/original/910513ce26b2b07a.png");
  background-size: contain;
  background-repeat: no-repeat;
  height:120px;
  width:241px;
  margin: -40px -40px -40px 0px;
}

.logo-container svg,
.navigation-panel__logo svg {
  display: none;
}

.account-card__header img,
body.lighter,
.app-body,
.tabs-bar__wrapper {
  background: #000112;
}

.link-footer p {
    color: #9aa3bf;
}

.about__section__body {
    border: 1px solid #030b20;
}

.about__domain-blocks__domain:nth-child(2n+1) {
    background-color: #070f24;
    border-bottom: 1px solid #405285;
}

.about__domain-blocks__domain:nth-child(2n) {
    background-color: #030b20;
    border-bottom: 1px solid #405285;
}

.status-card__image,
.account__header__bio .account__header__fields dl,
.about__section__title,
.about__meta,
.about__header__hero,
.scrollable .account-card {
    background-color: #030b20;
}

.rules-list li,
.getting-started__trends h4,
.notification__filter-bar,
.account__section-headline {
    border-bottom: 1px solid #405285;
}

.compose-panel hr,
.navigation-panel hr {
    border-top: 1px solid #405285;
}

.notification__filter-bar button.active:after {
    border-bottom: 1px solid #405285;
    border-color: transparent transparent #405285;
}

.about__meta__divider {
    border-color: #405285;
}

.notification__filter-bar button.active:before,
.account__section-headline a.active:after {
    border-color: transparent transparent #405285;
}

.account__header,
.column-back-button,
.status__prepend,
.account__section-headline button,
.notification,
.detailed-status,
.column-inline-form,
.empty-column-indicator,
.column>.scrollable {
    background-color: #001233;
}

.status {
    background-color: #001233;
    border-bottom: 1px solid #405285;
}

.focusable:focus {
    outline:0;
    background:#030b20;
}

.focusable:focus .detailed-status,
.focusable:focus .detailed-status__action-bar {
    background:#030b20;
}

.simple_form .block-button,
.simple_form .button,
.simple_form .button:hover,
.simple_form .button:active,
.simple_form .button:focus {
    color: #CAC0B3;
    background-color: #071754;
    border: 1px solid #405285;
    padding: 10px!important;
}

.simple_form button,
.button,
.button:active,
.button:focus {
    color: #FFFFFF;
    background-color: #FF595A;
}

.button:disabled {
    color: #444466;
    background-color: #FF595A;
}

.button:hover {
    background-color: #FF595A;
}

.button.button-secondary,
.button.button-secondary:active,
.button.button-secondary:hover,
.button.button-secondary:focus,
.button.button-secondary:disabled {
    color: #CAC0B3;
    background-color: #071754;
    border: 1px solid #405285;
}

.button.button-tertiary,
.button.button-tertiary:active,
.button.button-tertiary:hover,
.button.button-tertiary:focus,
.button.button-tertiary:disabled {
    color: #FF595A;
    background-color: #071754;
    border: 1px solid #405285;
    padding: 6px 17px;
}

.interaction-modal,
.filter-form,
.column-header__collapsible-inner,
.column-header__button.active,
.column-header__button.active:hover,
.column-header__back-button,
.account__section-headline,
.notification__filter-bar button,
.follow_requests-unlocked_explanation,
.dismissable-banner,
.search__input,
.column-header,
.column-header__button {
    background-color: #071754;
}

.detailed-status__action-bar {
    background-color: #071754;
    border-top: 1px solid #405285;
    border-bottom: 1px solid #405285;
}

.about__domain-blocks,
.status-card,
.status-card.compact {
    border: 1px solid #405285!important;
}

.server-banner__hero,
a.status-card.compact:hover,
a.status-card:hover {
    background-color:#030b20;
}

.status__content a {
    color: #CAC0B3;
}

.column-link--transparent.active {
    color: #CAC0B3;
}

.trends__item__sparkline path:first-child {
    fill: #FF595A8a!important;
    fill-opacity: 100%!important;
}

.trends__item__sparkline path:last-child {
    stroke: #FF595Ac4!important;
    fill: none!important;
}

.icon-button.active,
.notification__message .fa {
    color: #FF595A8a;
}

.announcements__item__content a.unhandled-link,
.reply-indicator__content a.unhandled-link,
.status__content a.unhandled-link {
    color: #CAC0B3;
}


.rules-list li:before {
    background: #FF595A;
}

.button.logo-button {
    background: #FF595A;
}

.admin-wrapper .sidebar ul .simple-navigation-active-leaf a:hover {
    background-color: #FF595A;
}

.admin-wrapper .content__heading__tabs a.selected:hover {
    background: #FF595A;
}

.simple_form button:hover {
    background-color: #FF595A;
}

.admin-wrapper .sidebar ul .simple-navigation-active-leaf a {
    background-color: #FF595A;
}

img[alt=":verified:"] {
  display: none;
}
